import httpService from '../../../services/http.service';
import { getServiceHostPreference } from '../../../services/utils/getServiceHostPreference';
import AppConst from '../../../constants/app.constant';

const getMetarTabData = async (icao_id) => {
  const url = `${getServiceHostPreference()}${AppConst.weatherApi}stations/${icao_id}/observations?limit=10`;
  const errorResponse = {
    errorMessage: `METARs not available for ${icao_id}`,
  };
  const {
    data,
  } = await httpService.http_get_content(url);
  if (data?.features?.length > 0) {
    return data.features.map((item) => item.properties.rawMessage);
  }
  return errorResponse;
};

export {
  getMetarTabData,
};
